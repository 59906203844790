@font-face {
    font-family: 'Nunito-Regular';
    src: url('../fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('../fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-ExtraBold';
    src: url('../fonts/Nunito-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Regular';
    src: url('../fonts/Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Medium';
    src: url('../fonts/Quicksand-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Quicksand-Bold';
    src: url('../fonts/Quicksand-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Nunito-Regular';
    background-color: rgb(245, 245, 245);
}

.modal-dialog-centered {
    width: 60%;
    margin-left: 20%;
    font-size: 14px;
}

b {
    font-family: 'Nunito-Bold';
}

a {
    text-decoration: none;
}

.fw-bold {
    font-weight: 900;
}

input::placeholder {
    font-size: 13px;
}

input[type="input"],
input[type="number"],
input[type="tel"],
input[type="textarea"] {
    font-size: 13px;
}

textarea::placeholder {
    font-size: 13px;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.card {
    z-index: 0;
}

.css-13cymwt-control,
.select__control {
    z-index: 0 !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 23px !important;
    color: red;
}

.modal-dialog-centered .header {
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
}

.modal-dialog-centered .ok {
    border: 1px solid grey;
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.modal-dialog-centered .header .close {
    font-size: 20px;
}

.modal-dialog-centered .message {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

.modal-dialog-centered .gonder,
.modal-dialog-centered .iptal {
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

/* login */

.register .header-img,
.login .header-img {
    width: 100%;
}

.register .header-img img,
.login .header-img img {
    width: 100%;
}

.register .logo,
.login .logo {
    margin-top: 20px;
    align-items: center;
    text-align: center;
}

.register .logo img,
.login .logo img {
    height: 80px;
}

.login-text {
    align-items: center;
    text-align: center;
    margin-top: 20px;
}

.login-text span {
    font-size: 15px;
}

.register .login-form,
.login .login-form {
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
}

.register .form,
.login .form {
    align-items: center;
}

.register .login-btn,
.login .login-btn {
    border-radius: 20px;
    background-color: grey;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 20px;
    width: 70%;
    font-family: 'Nunito-Bold';
}

.register .register-btn,
.login .register-btn {
    border-radius: 20px;
    border: 1px solid grey;
    background-color: white;
    color: grey;
    font-family: 'Nunito-Bold';
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
    width: 70%;
}

.register .texts,
.login .texts {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.register .texts a,
.login .texts a {
    font-size: 15px;
    text-decoration: none;
    color: grey;
}

.register .texts span,
.login .texts span {
    font-size: 15px;
}

.register .texts div,
.login .texts div {
    margin-left: 5px;
    margin-right: 5px;
}

/* login finish */


/* contracts */

.contracts .header {
    height: 50px;
    width: 100%;
    background-color: white;
    border-bottom: 1px solid rgb(220, 220, 220);
    display: flex;
    justify-content: space-between;
}

.contracts .header span {
    font-size: 18px;
    font-family: 'Quicksand-Medium';
}

.contracts .content {
    font-size: 14px;
    margin: 20px;
}

/* contracts finish */


/* header */

.header-navbar {
    height: 55px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    border-bottom: 0.3px solid rgb(186, 186, 186);
}

.header-navbar .basket {
    background-color: red;
    border-radius: 20px;
    position: fixed;
    top: 15px;
    width: 17px;
    height: 17px;
    font-weight: 100;
    letter-spacing: -1px;
    line-height: 19px;
    font-size: 12px;
    color: white;
    right: 15px;
    text-align: center;
}

.header-navbar .logo img {
    height: 40px;
}

.header-navbar .ss {
    display: flex;
    justify-content: space-around;
    margin-right: 5px;
}

.header-navbar .ss div {
    margin-right: 15px;
}

.header-navbar .ss img {
    height: 24px;
}

/* header finish */


/* homepage */

.homepage .slider,
.homepage .swiper {
    z-index: 0 !important;
    margin-top: 55px;
}

.homepage .slider img {
    width: 100%;
}

.homepage .vitrin {
    margin-top: 20px;
    margin-bottom: 60px;
}

.homepage .vitrin .row {
    width: 94%;
    margin-left: 3%;
    margin-top: 25px;
}

.homepage .vitrin .col-sm-6 {
    width: 50%;
    margin-bottom: 20px;
}

.homepage .vitrin .item {
    border-radius: 10px;
    height: 100px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    color: white;
    font-weight: 900;
    letter-spacing: 1px;
    background-image: linear-gradient(to right, rgb(0, 0, 0), rgb(62, 62, 62));
}

.homepage .vitrin a {
    text-decoration: none;
    color: white;
    font-size: 18px;
}

/* homepage finish */


/* bottom bar */

.bottom-bar {
    height: 60px;
    background-color: white;
    border-top: 1px solid rgb(220, 220, 220);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.bottom-bar .adest {
    background-color: red;
    border-radius: 20px;
    position: fixed;
    bottom: 32px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    letter-spacing: -1px;
    color: white;
    margin-left: 15px;
}

.bottom-bar .item {
    margin-left: 5px;
}

.bottom-bar img {
    height: 23px;
    filter: brightness(0.5);
}

.bottom-bar span {
    font-size: 17px;
    color: black;
    letter-spacing: -1px;
    font-weight: 500;
}

/* bottom bar finish */


/* drawer menu */

.drawer {
    z-index: 10000;
}

.drawermenu {
    background-color: white;
    z-index: 9;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
}

.drawermenu img {
    height: 120px;
}

.drawermenu .puan span {
    font-size: 14px;
    color: grey;
}

.drawermenu .urunler {
    margin-bottom: 20px;
    transition: 1s;
}

.drawermenu .urunler .item {
    display: flex;
    justify-content: space-between;
    height: 25px;
    transition: 1s;
}

.drawermenu .urunler .arrowdown {
    margin-top: -7px;
    transition: 1s;
}

.drawermenu .urunler h5 {
    font-size: 16px;
}

.drawermenu .urunler .item .title {
    font-size: 13px;
    transition: 1s;
}

.drawermenu .urunler .submenu {
    text-align: left;
    margin-top: 0px;
    margin-left: 10px;
    margin-bottom: 8px;
    transition: 1s;
}

.drawermenu .urunler .submenu span {
    font-size: 13px;
    color: grey;
    transition: 1s;
}


/* drawer menu finish */


/* filter */

.filter {
    background-color: red;
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 55px;
    width: 100%;
    left: 0;
}

.filter div {
    align-items: center;
}

.filter img {
    height: 20px;
}

.filter span {
    font-size: 14px;
    color: white;
}

.modal-filter {
    padding: 10px;
}

.modal-filter button {
    width: 100%;
    background-color: #242d41;
}

/* filter finish */


/* product */

.search .urunler {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: auto auto;
}

.urunlers {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 120px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: auto auto;
}

.search .urunler img,
.urunlers img {
    width: 100px;
}

.search .urunler span,
.urunlers span {
    font-size: 12px;
    /* color: grey; */
    line-height: 13px;
}

/* .search .urunler span,
.urunlers span {
    font-weight: 900;
} */

.search .urunler small,
.urunlers small {
    font-size: 10px;
    /* color: rgb(186, 186, 186); */
}

.search .urunler button,
.urunlers button {
    font-size: 12px;
    border: 0px;
    background-color: blue;
    border-radius: 20px;
}

.search .urunler .item {
    max-height: 300px;
    min-height: 300px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.urunlers .card {
    max-height: 300px;
    min-height: 300px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.product .initProducts {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.urunlers .initProduct {
    height: 280px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, rgb(61, 61, 61) 0%, rgb(68, 68, 68) 50%, rgb(61, 61, 61) 0%);
    border-radius: 10px;
    width: 100%;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.urunlers .initProduct img {
    height: 50px;
}

.notimg {
    display: none;
}

.animationimg {
    display: block;
}

.animationimg .arti1 {
    animation: animated_div 2s 1;
    -moz-animation: animated_div 2s 1;
    -webkit-animation: animated_div 2s 1;
    -o-animation: animated_div 2s 1;
}

#btnControl {
    display: none;
}

.arti1 {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: red;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 15px;
}

#btnControl:checked+label>.arti1 {
    z-index: 10000;
    transition: 2s;
    animation: animated_div 2s 1;
}

@keyframes animated_div {
    0% {
        position: absolute;
        margin-top: 0px;
        display: none;
        opacity: 1;
    }

    25% {
        position: absolute;
        margin-top: 0px;
        opacity: 1;
    }

    50% {
        position: absolute;
        margin-top: 0px;
        opacity: 0.5;
    }

    100% {
        position: absolute;
        /* bottom: 30px; */
        left: 50%;
        margin-top: 340px;
        opacity: 0;
    }
}


/* product finish */


/* product-detail */

.product-detail .slider {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 70px;
}

.product-detail .slider img {
    height: 200px;
}

.product-detail .sepet-ekle button {
    background-color: red;
    border: 0;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
    border-radius: 15px;
}

.product .adet {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border: 1px solid grey;
    border-radius: 10px;
    width: 20%;
    margin-left: 40%;
}

.product .adet svg {
    margin-top: 7px;
}

.product .adet input {
    width: 50px;
    border: 0;
    font-size: 12px;
    text-align: center;
    background-color: white;
    font-weight: 900;
}

.product-detail .adet {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.product-detail .adet svg {
    margin-top: 7px;
}

.product-detail .adet input {
    width: 50px;
    text-align: center;
}

.product-detail .adet div {
    margin-left: 10px;
    margin-right: 10px;
}


.product-detail .cargo {
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
    font-family: 'Nunito-Bold';
}

.product-detail .cargo img {
    margin-right: 10px;
}

.product-detail .properties span {
    font-size: 14px;
}

/* product-detail finish */


.productTitle {
    height: 56px;
    color: black;
    font-weight: 900;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical
}


/* basket */

.basket .my-basket {
    margin-bottom: 180px !important;
    width: 90%;
    margin-left: 5%;
    margin-top: 80px;
}

.basket .my-basket span {
    font-size: 12px;
    display: block;
}

.basket .my-basket small {
    color: grey;
    font-size: 10px;
}

.basket .my-basket img {
    width: 100px;
}

/* .basket .my-basket .item {
    padding-left: 15px;
    padding-right: 15px;
} */

.basket .my-basket input {
    width: 45px;
}

.basket .tamamla {
    background-color: rgb(245, 245, 245);
    position: fixed;
    bottom: 50px;
    width: 100%;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
}

.basket .tamamla .bosalt {
    border-radius: 10px;
    border: 0;
    width: 100%;
    font-size: 14px;
}

.basket .tamamla .satin {
    border-radius: 10px;
    background-color: red;
    width: 100%;
    border: 0;
    font-size: 14px;
}

.basket .initProducts {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.basket .initProduct {
    height: 130px;
    margin-bottom: 10px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, rgb(61, 61, 61) 0%, rgb(68, 68, 68) 50%, rgb(61, 61, 61) 0%);
    border-radius: 10px;
    width: 100%;
    animation: blink 1s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.basket .initProduct img {
    height: 50px;
}

.basket .my-basket .animation-delete {
    background-color: white;
    height: 130px;
}


/* basket finish */


/* pay */

.pay .odeme {
    margin-top: 90px;
    width: 90%;
    margin-left: 5%;
}

.pay .odeme .odeme-tutar {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
}

.pay .odeme .odeme-tutar div {
    margin-left: 2px;
    margin-right: 2px;
}

.pay .odeme .odeme-tutar .ana input {
    width: 80px;
}

.pay .odeme .odeme-tutar .kusur input {
    width: 40px;
}

.pay .odeme-sekli,
.pay-info .odeme-sekli {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
    font-size: 14px;
}

.taksitler {
    margin-top: 30px;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
}

.taksitler .item {
    border: 1px solid rgb(193, 193, 193);
    border-radius: 10px;
    padding: 10px 5px;
    margin-bottom: 10px;
    text-align: center;
    margin-right: 3px;
    margin-left: 3px;
}

.taksitler .selectedItem {
    border: 1px solid rgb(193, 193, 193);
    border-radius: 10px;
    padding: 10px 5px;
    margin-bottom: 10px;
    text-align: center;
    margin-right: 3px;
    margin-left: 3px;
}

/* pay finish */


/* SWİTCH */

.pay-info {
    margin-bottom: 100px;
}

.address-edit .switch,
.pay-info .switch,
.pay .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
}

.address-edit .switch input,
.pay-info .switch input,
.pay .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.address-edit .switch .slider,
.pay-info .switch .slider,
.pay .switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.address-edit .switch .slider:before,
.pay-info .switch .slider:before,
.pay .switch .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 0px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.address-edit .switch input:checked+.slider,
.pay-info .switch input:checked+.slider,
.pay .switch input:checked+.slider {
    background-color: red;
}

.address-edit .switch input+.slider,
.pay-info .switch input:focus+.slider,
.pay .switch input:focus+.slider {
    box-shadow: 0 0 1px red;
}

.address-edit .switch input:checked+.slider:before,
.pay-info .switch input:checked+.slider:before,
.pay .switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.address-edit .switch .slider.round,
.pay-info .switch .slider .round,
.pay .slider.round {
    border-radius: 34px;
}

.address-edit .switch .slider.round:before,
.pay-info .switch .slider .round:before,
.pay .slider.round:before {
    border-radius: 50%;
}

.pay .odeme-tamamla button {
    border-radius: 15px;
    background-color: red;
    width: 100%;
    border: 0;
    margin-top: 50px;
    font-size: 14px;
}

/* SWİTCH FİNİSH */


/* search */

.search .searchfield {
    display: flex;
    margin-top: 55px;
}

.search .searchfield .inputs {
    width: 88%;
}

.search .searchfield .inputs input {
    border: 0px;
    height: 50px;
}

.search .searchfield .imgs {
    width: 22%;
    background-color: white;
    padding-top: 14px;
}

.search .searchfield span {
    height: 20px;
    border: 1px solid rgb(203, 203, 203);
    margin-top: 15px;
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 15px;
    margin-left: 15px;
}

/* search finish */


/* profile */

.profile .name {
    position: fixed;
    top: 55px;
    background-color: red;
    width: 100%;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
    text-align: center;
}

.profile .menus {
    margin-top: 130px;
    width: 100%;
}

.profile .menus div {
    border: 2px solid rgb(220, 220, 220);
    margin-bottom: 20px;
    width: 44%;
    margin-left: 4%;
    float: left;
    height: 100px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-family: 'Nunito-Bold';
}

/* profile finish */


/* order */

.orders .date-print {
    margin-top: 85px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.orders .date-print div {
    margin-left: 4px;
    margin-right: 4px;
}

.orders .date-print img {
    height: 28px;
}

.orders .headers {
    display: flex;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
}

.orders .headers div {
    background-color: white;
    height: 45px;
    border: 1px solid rgb(220, 220, 220);
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.orders .headers span {
    font-size: 12px;
}

.orders .order-table {
    margin-top: 20px;
    background-color: white;
    margin-bottom: 80px;
}

.orders .order-table .item {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid rgb(186, 186, 186);
    padding-bottom: 4px;
    padding-top: 4px;
    padding-left: 10px;
}

.orders .order-table .item b {
    font-size: 13px;
}

.orders .order-table .item small {
    font-size: 11px;
}

/* order finish */


/* order-detail */

.order-detail .prints {
    margin-top: 80px;
    border: 1px solid rgb(186, 186, 186);
    width: 90%;
    margin-left: 5%;
    padding-bottom: 10px;
}

.order-detail .prints .card {
    width: 40%;
    margin-left: 30%;
}

.order-detail .fiyat-miktar .card {
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
}

.order-detail .fiyat-miktar .card small {
    font-size: 10px;
    color: grey;
}

.order-detail .fiyat-miktar .card b {
    font-size: 12px;
}

.order-detail .siparis-tutar .card {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.order-detail .siparis-tutar .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .siparis-tutar .card small {
    color: grey;
    font-size: 11px;
}

.order-detail .teslimat .card {
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}

.order-detail .teslimat .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .teslimat .card small {
    font-size: 11px;
    color: grey;
}

.order-detail .odeme-yontem .card {
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    padding: 10px;
}

.order-detail .odeme-yontem .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .odeme-yontem .card small {
    font-size: 11px;
    color: grey;
    display: block;
}

.order-detail .odeme-notu .card {
    margin-bottom: 80px;
    margin-top: 20px;
    width: 90%;
    margin-left: 5%;
    padding: 10px;
}

.order-detail .odeme-notu .card b {
    font-size: 13px;
    color: rgb(116, 116, 116);
}

.order-detail .odeme-notu .card small {
    font-size: 11px;
    color: grey;
    display: block;
}


/* order-detail finish */


/* address */

.my-address .basliklar {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
}

.my-address .basliklar div {
    background-color: white;
    height: 45px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin-left: 2px;
    margin-right: 2px;
    border: 1px solid rgb(220, 220, 220);
}

.my-address .basliklar b {
    font-size: 12px;
}

.my-address .addresses {
    background-color: white;
    margin-top: 30px;
}

.my-address .addresses .item {
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 30px;
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-bottom: 5px;
    padding-top: 5px;
}

.my-address .addresses .item img {
    height: 18px;
}

.my-address button {
    width: 90%;
    margin-left: 5%;
    border-radius: 10px;
    font-size: 15px;
    background-color: red;
    border: 0;
    margin-top: 30px;
}

/* address finish */


/* address edit */

.address-edit .duzenle {
    margin-top: 80px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 100px;
}

.address-edit .duzenle input {
    margin-bottom: 10px;
}

.address-edit .duzenle span {
    font-size: 13px;
    color: grey;
}

.address-edit .duzenle button {
    background-color: red;
    border-radius: 20px;
    border: 0;
    width: 100%;
    margin-top: 30px;
    font-size: 14px;
}

/* address edit finish */


/* account */

.account .info {
    margin-top: 80px;
    margin-bottom: 100px;
    width: 90%;
    margin-left: 5%;
}

.account .info b {
    font-size: 14px;
}

.account .info button {
    background-color: red;
    border: 0;
    border-radius: 15px;
    margin-top: 20px;
    font-size: 15px;
    width: 100%;
}

.account .info .forms div {
    margin-top: 8px;
    font-size: 13px;
    color: grey;
}

/* account finish */


/* mypayments */

.my-payments .date-print {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.my-payments .date-print span {
    font-size: 14px;
}

.my-payments .date-print div {
    margin-left: 5px;
    margin-right: 5px;
}

.my-payments .date-print input {
    width: 100%;
}

.my-payments .date-print img {
    height: 28px;
    margin-bottom: 7px;
}

.my-payments .tables {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.my-payments .tables div {
    background-color: white;
    border: 1px solid rgb(220, 220, 220);
    height: 45px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}

.my-payments .tabless {
    margin-top: 20px;
    background-color: white;
    margin-bottom: 80px;
}

.my-payments .tabless .item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(186, 186, 186);
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.my-payments .tabless .item small {
    font-size: 16px;
    font-weight: 700;
}

/* mypayments finish */


.about .content {
    margin-top: 70px;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 100px;
    font-size: 17px;
    color: black !important;
    font-weight: 700;
}


/* adres bilgileri */

.address-info .sao {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    align-items: center;
}

.address-info .sao div {
    border: 1px solid grey;
}

.address-info .sao .line {
    height: 1px;
    border: 0.5px solid grey;
    width: 30px;
}

.address-info .sao .section {
    border-radius: 10px;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.address-info .sao .select {
    background-color: red;
    color: white;
    border: 0;
}

.address-info .tes-adres {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.address-info .kargo {
    font-size: 13px;
    padding-left: 35px;
}

.address-info .description {
    margin-top: 30px;
    width: 86%;
    margin-left: 7%;
    font-size: 14px;
}

.address-info .tes-adres span {
    font-size: 13px;
}

.address-info .tes-adres small {
    font-size: 10px;
}

.address-info .tamamla button {
    background-color: red;
    border-radius: 15px;
    border: 0;
    width: 86%;
    margin-left: 7%;
    margin-top: 30px;
    font-size: 14px;
}

/* adres bilgileri finish */


/* pay info */

.pay-info .sao {
    display: flex;
    justify-content: center;
    margin-top: 90px;
    align-items: center;
}

.pay-info .sao div {
    border: 1px solid grey;
}

.pay-info .sao .line {
    height: 1px;
    border: 0.5px solid grey;
    width: 30px;
}

.pay-info .sao .section {
    border-radius: 10px;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.pay-info .sao .select {
    background-color: red;
    color: white;
    border: 0;
}

.pay-info .info span {
    font-size: 11px;
}

.pay-info .info small {
    color: #ccc;
}

.pay-info .info .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pay-info .info .item div {
    width: 33%;
}

.pay-info .odeme-seklis {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pay-info .odeme-seklis div {
    border: 1px solid grey;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
}

.pay-info .odeme-seklis .active {
    border-bottom: 5px solid grey;
    transition: 0.3s;
}

.pay-info .joker {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 12px;
}

.pay-info .satissozlesmesi {
    margin-left: 25px;
    margin-top: 15px;
    font-size: 12px;
}

.pay-info .view .tamamla button {
    background-color: red;
    border: 0;
    border-radius: 15px;
    font-size: 13px;
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
}

.pay-info .modal-dialog-centered {
    font-size: 12px;
}

.pay-info .havale {
    font-size: 13px;
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    margin-bottom: 120px;
}

.pay-info .havale .select {
    width: 16px;
    height: 16px;
    border: 1px solid grey;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.pay-info .havale .selected {
    width: 16px;
    height: 16px;
    border: 5px solid blue;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.pay-info .havale .card {
    margin-bottom: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.pay-info .havale .tamamla button {
    background-color: red;
    border: 0;
    border-radius: 15px;
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    font-size: 14px;
}

.pay-info .cari-borc {
    margin-top: 20px;
    font-size: 13px;
}

.pay-info .cari-borc .card {
    padding: 10px;
    width: 86%;
    margin-left: 7%;
}

.pay-info .cari-borc .card .select {
    width: 16px;
    height: 16px;
    border: 5px solid blue;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.pay-info .cari-borc .tamamla button {
    background-color: red;
    border: 0;
    border-radius: 15px;
    width: 86%;
    margin-left: 7%;
    margin-top: 20px;
    font-size: 14px;
}

/* pay info finish */

ul {
    list-style: none;
}

.accordion-menu {
    background: #242d41;
    border-radius: 2px;
    overflow: hidden;
}

.accordion-menu li:last-child .dropdown {
    border-bottom: 0;
}

.accordion-menu li.active .dropdown {
    color: #fff;
}

.accordion-menu li.active .dropdown .fa-chevron-down {
    transform: rotate(180deg);
}

.dropdown {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    /* padding: 15px 15px 15px 45px; */
    font-size: 18px;
    border-bottom: 2px solid #2d374d;
    color: #999999;
    position: relative;
    transition: all 0.4s ease-out;

}

.dropdown:hover {
    background: #566f82;
    /* fallback colour */
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#27455c), to(#273a47));
}

.dropdown i {
    position: absolute;
    top: 17px;
    left: 16px;
}

.dropdown .fa-chevron-down {
    right: 12px;
    left: auto;
    transition: transform 0.2s ease-in-out;
}

.dropdown .link .subMenuItems {
    display: none;
    background: #2d374d;
    transition: all 2s ease-in-out;
}

.accordion-menu li.active .subMenuItems {
    display: block;
}

.subMenuItems a {
    display: block;
    color: white;
    font-weight: 600;
    padding: 12px 12px 12px 45px;
    transition: all 0.2s ease-out;
    text-decoration: none;
}

.subMenuItems a:hover {
    background-color: #5c7185;
    color: #fff;
}

/* Brands */
.homepage .brandItem {
    border: 1px solid #d0d0d0;
    border-radius: 10px;
    padding: 10px 20px;
    height: 100px;
    display: flex;
    justify-content: center;
    font-family: 'Nunito-Bold';
    font-weight: 900;
    align-items: center;
    text-align: center;
    filter: drop-shadow(0px 7px 6px black);
}

.swiper-button-next, .swiper-rtl .swiper-button-prev, .swiper-button-prev, .swiper-rtl .swiper-button-next {
    font-weight: 900 !important;
}